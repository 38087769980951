<template>
  <div>
    <!-- search input -->
    <section id="techops-categories">
      <b-card class="mt-n1">
        <b-progress v-if="searching" :value="barValue" max="100" />
        <b-overlay :show="searching" rounded="sm">
          <b-row>
            <b-col cols="6" class="text-center">
              <h4 class="pr-5">
                Builds Today
              </h4>
            </b-col>
            <b-col cols="6" class="text-center">
              <h4 class="pl-5">
                Totals
              </h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <statistic-card-vertical :statistic="filteredCounts('build')" statistic-title="Assigned" color="info"
                class="mb-0" />
            </b-col>
            <b-col>
              <statistic-card-vertical :statistic="filteredCounts('approve')" statistic-title="In Approval" color="info"
                class="mb-0" />
            </b-col>
            <b-col>
              <statistic-card-vertical :statistic="filteredCounts(' ship')" statistic-title="In Ship" color="info"
                class="mb-0" />
            </b-col>
            <b-col cols="1">
              <div style="border-left: 2px dotted; height: 80%; margin-left:50%; margin-top:5%;" />
            </b-col>
            <b-col>
              <statistic-card-vertical :statistic="stats.results.yesterday" statistic-title="Yesterday" color="info"
                class="mb-0" />
            </b-col>
            <b-col>
              <statistic-card-vertical :statistic="stats.results.week" statistic-title="Week" color="info"
                class="mb-0" />
            </b-col>
            <b-col>
              <statistic-card-vertical :statistic="stats.results.month" statistic-title="Month" color="info"
                class="mb-0" />
            </b-col>
          </b-row>
        </b-overlay>
      </b-card>
    </section>
    <section>

      <b-card class="mt-n1">
        <b-row>
          <b-col cols="4">
            <b-form @submit.prevent>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input id="searchbar" v-model="invoiceSearch" placeholder="Search an Invoice"
                  @keypress="isNumber($event)" @keyup.enter="openInvoice()" />
                <b-input-group-append is-text>
                  <b-button @click="openInvoice()">
                    <feather-icon icon="CheckIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form>
            {{ message }}
          </b-col>
        </b-row>
      </b-card>
    </section>

    <section id="techops-content">
      <invoices-table :data="invoices" title="Ready for Approval" filter-type="approve" />
      <invoices-table :data="invoices" title="Not Assigned" filter-type="not assigned" />
      <invoices-table :data="invoices" title="Ready for Build" filter-type="build" />
      <invoices-table :data="invoices" title="Ready to Ship" filter-type=" ship" />
      <invoices-table :data="invoices" title="Shipped" filter-type="shipped" />
      <invoices-table :data="invoices" title="Delivered" filter-type="delivered" />
    </section>
  </div>
</template>

<script>
import {
  BCard, BProgress, BRow, BCol, BOverlay, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import InvoicesTable from './TechOpsInvoicesTable.vue'

export default {
  components: {
    BCard,
    BProgress,
    BRow,
    BCol,
    BOverlay,
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    StatisticCardVertical,
    InvoicesTable,
  },
  data() {
    return {
      timer: 0,
      timer2: 0,
      barValue: 0,
      searching: true,
      message: '',
      invoiceSearch: '',
      stats: {},
      invoices: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            invoiceDate: '',
            txnID: '',
            buildBuiltByID: '',
            buildModified: '',
            isShipped: true,
            shipMethod: '',
            trackingFull: '',
            buildNoUnits: 0,
            buildUID: '',
            tracking: '',
            customerName: '',
            subTotal: 0.0,
            shipDate: '',
            isDelivered: false,
            buildComplete: false,
            deliverDateActual: '',
            buildApprovedByID: '',
            buildBuiltBy: '',
            buildStatus: '',
            invoiceProduct: '',
            refNumber: '',
            buildEntered: '',
            buildApprovedBy: '',
            shipDateActual: '',
            buildCompleted: '',
            repName: '',
          },
        ],
      },
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) {
        this.barValue = 0
      }
      this.barValue += 5
    }, 600)
  },
  async created() {
    this.$http.get('https://asi-api2.azure-api.net/techs/summary/stats/shippedwtech?key=1ea49cfa0a144e7daf97f1e42601ca05').then(res => { this.stats = res.data })
    await this.$http.get('https://asi-api2.azure-api.net/techs/summary?key=1ea49cfa0a144e7daf97f1e42601ca05').then(res => { this.invoices = res.data; this.searching = false })
    this.timer2 = setInterval(() => {
      this.searching = true
      this.$http.get('https://asi-api2.azure-api.net/techs/summary/stats/shippedwtech?key=1ea49cfa0a144e7daf97f1e42601ca05').then(res => { this.stats = res.data })
      this.$http.get('https://asi-api2.azure-api.net/techs/summary?key=1ea49cfa0a144e7daf97f1e42601ca05').then(res => { this.invoices = res.data; this.searching = false })
    }, 300000)
  },
  methods: {
    async openInvoice() {
      if (this.invoiceSearch.length > 3 && this.invoiceSearch.length < 7) {
        this.message = 'searching'
        // let invoiceLookup = {}
        // await this.$http.get(`https://asi-api2.azure-api.net/invoice/check/${this.invoiceSearch}?key=55d85562ab5f47dba870ae9caf584081`).then(res => { invoiceLookup = res.data })
        // if (invoiceLookup.queryCount > 0) {
        this.$router.push({
          name: 'techops-edit',
          params: { id: this.invoiceSearch },
        })
        // } else this.message = 'please enter a valid invoice number'
      } else { this.message = 'please enter a valid invoice number' }
    },
    filteredCounts(filterName) {
      return this.invoices.results.filter(item => item.buildStatus.toLowerCase().includes(filterName)).length
    },
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      }
      return true
    },
  },
}
</script>
