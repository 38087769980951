<template>
  <b-card
    v-if="filteredResults[0]"
    class="mt-n1"
  >
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card-header class="pt-0 pl-0">
        <b-card-title class="font-medium-1">
          {{ title }} - ({{ filteredResults.length }})
        </b-card-title>
      </b-card-header>
      <b-card-body class="font-medium-1 mb-0 pb-0">
        <b-table
          hover
          striped
          :items="filteredResults"
          :fields="fields"
          class="mb-0"
          style="text-align: center;"
          @row-clicked="rowClicked"
        ><template #cell(invoiceDate)="row">
           <div style="min-width:100px">
             {{ row.item.invoiceDate | moment("MMM DD") }}
           </div>
         </template>
          <template #cell(subTotal)="row">
            <div style="min-width:125px">
              {{ row.item.subTotal | toCurrency() }}
            </div>
          </template>
          <template #cell(invoiceProduct)="row">
            <div
              style="min-width:300px"
              class="text-truncate"
            >
              {{ row.item.invoiceProduct }}
            </div>
          </template>
          <template #cell(buildBuiltBy)="row">
            <div style="min-width:125px">
              {{ row.item.buildBuiltBy }}
            </div>
          </template>
          <template #cell(buildApprovedBy)="row">
            <div style="min-width:125px">
              {{ row.item.buildApprovedBy }}
            </div>
          </template>
        </b-table>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BOverlay, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BOverlay,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => {},
    },
    filterType: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      fields: [
        { key: 'invoiceDate', label: 'INVOICE DATE', tdClass: 'width150px' },
        { key: 'refNumber', label: 'INVOICE' },
        { key: 'subTotal', label: 'AMOUNT' },
        { key: 'repName', label: 'REP' },
        { key: 'invoiceProduct', label: 'PRODUCT' },
        { key: 'buildBuiltBy', label: 'BUILD TECH' },
        { key: 'buildApprovedBy', label: 'APPROVER' },
      ],
    }
  },
  computed: {
    filteredResults() {
      let results = this.data.results.filter(item => item.buildStatus.toLowerCase().includes(this.filterType))
      results = results.sort((a, b) => {
        const fa = a.invoiceDate.toLowerCase()
        const fb = b.invoiceDate.toLowerCase()
        if (fa > fb) {
          return -1
        }
        if (fa < fb) {
          return 1
        }
        return 0
      })
      return results
    },
  },
  watch: {
    data(val) {
      if (val.queryCount === 0) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    rowClicked(items) {
      this.$router.push({
        name: 'techops-edit',
        params: { id: items.refNumber },
      })
    },
  },
}
</script>
<style>
.width150px {
   width: 150px;
}
.width300px {
   width: 300px;
}
.width400px {
   width: 400px;
}
.card {
  display: flex !important;
  flex-direction: column;
}
.card-body {
  flex-grow: 1;
  overflow: auto;
}
</style>
